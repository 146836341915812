import { Helper, useGlobalProperties } from "@veridapt/core";
import {
  BrowserCustomEvents,
  BrowserGlobalProperties,
  useCustomEvents,
} from "..";

export interface HTTPHelper extends Helper {
  get: HTTPRequestFunction<string>;
  getJSON: HTTPRequestFunction<Record<string, unknown>>;
  post: HTTPRequestFunction<string>;
  postJSON: HTTPRequestFunction<Record<string, unknown>>;
  delete: HTTPRequestFunction<string>;
  deleteJSON: HTTPRequestFunction<Record<string, unknown>>;
  patch: HTTPRequestFunction<string>;
  patchJSON: HTTPRequestFunction<Record<string, unknown>>;
  put: HTTPRequestFunction<string>;
  putJSON: HTTPRequestFunction<Record<string, unknown>>;
}

export enum HTTPCacheType {
  Force = "force",
  Mutex = "mutex",
}

export type HTTPHelperOptions = {
  cache?: HTTPCacheType;
  context?: EventTarget;
};

type HTTPRequestData = string[][] | FormData | Record<string, string>;

type HTTPRequestFunction<T> = (
  url: string,
  data?: HTTPRequestData
) => Promise<T | null>;

export enum HTTPRequestMethod {
  Get = "get",
  Post = "post",
  Delete = "delete",
  Patch = "patch",
  Put = "put",
}

export const HTTPRequestMethods: HTTPRequestMethod[] = [
  HTTPRequestMethod.Get,
  HTTPRequestMethod.Post,
  HTTPRequestMethod.Delete,
  HTTPRequestMethod.Patch,
  HTTPRequestMethod.Put,
];

enum HTTPResponseDataType {
  JSON = "json",
  Text = "text",
}

export type HTTPResponseEventDetail =
  | Pick<Response, "status" | "statusText">
  | undefined;

export const useHTTP = (helperOptions?: HTTPHelperOptions): HTTPHelper => {
  const { cache, context = globalThis } = helperOptions ?? {};
  const customEvents = useCustomEvents(context);
  const globalProperties = useGlobalProperties();
  const contentCache = new Map<string, Promise<unknown>>();

  const destroy = () => {
    customEvents.destroy();
    globalProperties.destroy();
  };

  const buildRequestFunction =
    <T>(method: HTTPRequestMethod, dataType = HTTPResponseDataType.Text) =>
    async (url: string, data?: HTTPRequestData): Promise<T | null> => {
      return (async (): Promise<T | null> => {
        let content = null;
        try {
          customEvents.trigger(BrowserCustomEvents.HTTPLoading);
          content = await getContent<T>();
        } catch (error) {
          customEvents.trigger(BrowserCustomEvents.HTTPError);
        } finally {
          customEvents.trigger(BrowserCustomEvents.HTTPDone);
        }
        return content;
      })();

      function buildRequest(): Request {
        const body = buildRequestBody();
        const headers = buildRequestHeaders();
        const queryString = buildRequestQueryString();
        const redirect = process.env.NODE_ENV == "test" ? "follow" : "manual";
        const requestURL =
          queryString && method === HTTPRequestMethod.Get
            ? `${url}?${queryString}`
            : url;

        return new Request(requestURL, {
          body,
          cache: "no-store",
          headers,
          method: method.toUpperCase(),
          redirect,
        });
      }

      function buildRequestBody() {
        if (method !== HTTPRequestMethod.Get) {
          return data instanceof FormData ? data : new URLSearchParams(data);
        }
      }

      function buildRequestCacheID(): string {
        const queryString = buildRequestQueryString();
        return `${method}::${url}${queryString ? `::${queryString}` : ""}`;
      }

      function buildRequestHeaders(): Headers | undefined {
        const headers = new Headers({
          "Veri-Fetch": "true",
        });

        if (method !== HTTPRequestMethod.Get) {
          const csrfToken = globalProperties.get(
            BrowserGlobalProperties.CSRFToken
          );
          if (csrfToken) {
            headers.append("X-CSRF-Token", csrfToken);
          }
        }

        return headers;
      }

      function buildRequestQueryString(): string | undefined {
        if (method === HTTPRequestMethod.Get && !(data instanceof FormData)) {
          return new URLSearchParams(data).toString();
        }
      }

      async function fetchContent<T>(): Promise<T | null> {
        const request: Request = buildRequest();
        const response = await fetch(request);
        return handleResponse<T>(response);
      }

      async function getContent<T>(): Promise<T | null> {
        const cacheID = buildRequestCacheID();
        if (cache && contentCache.has(cacheID)) {
          return contentCache.get(cacheID) as Promise<T>;
        }

        const contentPromise = fetchContent<T>();
        if (cache) {
          contentCache.set(cacheID, contentPromise);
        }

        if (cache == HTTPCacheType.Mutex) {
          const content = await contentPromise;
          contentCache.delete(cacheID);
          return content;
        }

        return contentPromise;
      }

      async function handleResponse<T>(response: Response): Promise<T | null> {
        if (response.redirected || response.type == "opaqueredirect") {
          customEvents.trigger(
            BrowserCustomEvents.HTTPRedirect,
            response as HTTPResponseEventDetail
          );
        } else if (response.ok) {
          return (await (dataType === HTTPResponseDataType.JSON
            ? response.json()
            : response.text())) as T;
        } else {
          customEvents.trigger(
            BrowserCustomEvents.HTTPError,
            response as HTTPResponseEventDetail
          );
        }
        return null;
      }
    };

  return HTTPRequestMethods.reduce(
    (helper, method) => ({
      ...helper,
      [method]: buildRequestFunction<string>(method),
      [`${method}JSON`]: buildRequestFunction<Record<string, unknown>>(
        method,
        HTTPResponseDataType.JSON
      ),
    }),
    { destroy } as HTTPHelper
  );
};
