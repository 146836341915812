// Usage
//
// <div data-controller="destroy"
//      data-destroy-undo="Undo"
//      data-destroy-destroy="Delete">
//   <input name="parent[child][0][name]" value="" data-destroy-target="disable">
//   <select name="parent[child][0][region]" data-destroy-target="disable">
//   </select>
//   <input type="hidden" name="parent[child][0][_destroy]" value="false"
//      data-destroy-target="destroyInput" >
//   <a href="#" data-action="destroy#toggle">Delete</a>
// </div>

import { ApplicationController } from "../shared/application-controller";

export default class extends ApplicationController {
  static values = {
    markedForDestruction: Boolean,
    destroyLabel: String,
    undoLabel: String,
  };
  static targets = ["destroyInput", "disable", "toggleButton"];

  declare readonly destroyLabelValue: string | undefined;
  declare readonly undoLabelValue: string | undefined;
  declare readonly destroyInputTargets: Element[];
  declare readonly toggleButtonTargets: Element[];
  declare readonly disableTargets: Element[];

  toggle(event: Event): void {
    event.preventDefault();

    this.markedForDestructionValue = !this.markedForDestructionValue;
  }

  markedForDestructionValueChanged(value: boolean): void {
    this.destroyInputTargets.forEach((element) => {
      element.value = value;
    });
    this.toggleButtonTargets.forEach((element) => {
      element.textContent = value
        ? this.undoLabelValue
        : this.destroyLabelValue;
    });
    this.disableTargets.forEach((element) => {
      element.disabled = value;
    });
  }
}
