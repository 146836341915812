import { Helper } from "@veridapt/core";

type HTMLSanitizer = (rawHTML: string) => string;

const customElementTagNameRegExp = /^((?:\w+-)+\w+)$/;

export interface HTMLHelper extends Helper {
  sanitize: (rawHTML: string) => Promise<string>;
}

const importHTMLSanitizer = async (): Promise<HTMLSanitizer> => {
  const DOMPurify = await import("dompurify");

  DOMPurify.addHook("uponSanitizeElement", function (node, data) {
    if (customElementTagNameRegExp.exec(node.nodeName)) {
      data.allowedTags[data.tagName] ??= true;
    }
  });

  DOMPurify.addHook("uponSanitizeAttribute", function (node, data) {
    if (customElementTagNameRegExp.exec(node.nodeName)) {
      data.allowedAttributes[data.attrName] ??= true;
    }
  });

  return (rawHTML) =>
    DOMPurify.sanitize(rawHTML, {
      ADD_ATTR: ["autofocus", "formaction", "target"],
      FORCE_BODY: true,
    }).trim();
};

export function useHTML(): HTMLHelper {
  let sanitizeHTML: HTMLSanitizer | undefined;

  const destroy = () => undefined;

  const sanitize = async (rawHTML: string): Promise<string> => {
    try {
      sanitizeHTML ??= await importHTMLSanitizer();
      return sanitizeHTML(rawHTML);
    } catch (e) {
      return "";
    }
  };

  return {
    destroy,
    sanitize,
  };
}
