import { ApplicationController } from "../shared/application-controller";
import {
  DOMHelper,
  HTTPHelper,
  HTTPRequestMethod,
} from "@veridapt/browser-helpers";

export default class extends ApplicationController {
  dom: DOMHelper | undefined;
  http: HTTPHelper | undefined;

  connect(): void {
    this.dom = this.useDOM({ context: this.element });
    this.http = this.useHTTP({ context: this.element });
  }

  load(event: MouseEvent): void {
    const anchor = this.getTargetAnchor(event);
    if (anchor) {
      const { dataset, href } = anchor;

      // NB: Support temporarily disabling link by swapping in '#'
      //     with anchor href during HTTP request for updated content.
      if (href !== "#") {
        let content;
        void (async () => {
          try {
            anchor.href = "#";
            content = await this.requestContent(href, dataset.method);
          } finally {
            anchor.href = href;
          }
          if (typeof content == "string") {
            return this.handleResponseContent(content);
          }
        })();
      }

      this.cancel(event);
    }
  }

  cancel(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  private getTargetAnchor(event: MouseEvent): HTMLAnchorElement | null {
    const targetElement = event.target as HTMLElement;
    return targetElement.closest("a");
  }

  private async requestContent(
    path: string,
    method?: string
  ): Promise<string | null | undefined> {
    if (this.http && path && path !== "#") {
      const requestMethod =
        (method as HTTPRequestMethod) ?? HTTPRequestMethod.Get;
      return await this.http[requestMethod](path);
    }
  }

  private async handleResponseContent(content: string): Promise<void> {
    if (this.dom) {
      const fragment = await this.dom.handleResponseContent(content);
      if (fragment) {
        this.dom.replaceElement(this.element, fragment);
      }
    }
  }
}
