import * as I18n from "i18n-js";
import { CoreGlobalProperties, Helper, useGlobalProperties } from "..";

export interface I18nHelper extends Helper {
  hasTranslation: (
    scope: I18n.Scope,
    options?: I18n.TranslateOptions
  ) => boolean;
  translate: (scope: I18n.Scope, options?: I18n.TranslateOptions) => string;
}

export const useI18n = (): I18nHelper => {
  const globalProperties = useGlobalProperties();

  // Work-around to support i18n-js global-based configuration.
  const i18nConfig =
    globalProperties.get(CoreGlobalProperties.I18nConfig) ?? "{}";
  Object.assign(I18n, JSON.parse(i18nConfig));

  const buildFullScope = (
    scope: I18n.Scope,
    options?: I18n.TranslateOptions
  ): string => {
    const separator = (options?.separator as string) ?? I18n.defaultSeparator;
    const optionsScope = options?.scope ?? [];
    const optionsScopeKeys = Array.isArray(optionsScope)
      ? optionsScope
      : optionsScope.split(separator);
    const updatedOptionsScope = ["js", ...optionsScopeKeys].join(separator);
    return I18n.getFullScope(scope, { scope: updatedOptionsScope });
  };

  const destroy = () => {
    globalProperties.destroy();
  };

  const hasTranslation = (
    scope: I18n.Scope,
    options?: I18n.TranslateOptions
  ) => {
    const fullScope = buildFullScope(scope, options);
    return (
      I18n.translate(fullScope) !==
      I18n.missingTranslation(fullScope, { ...options, scope: undefined })
    );
  };

  const translate = (scope: I18n.Scope, options?: I18n.TranslateOptions) => {
    const fullScope = buildFullScope(scope, options);
    return I18n.translate(fullScope, { ...options, scope: undefined });
  };

  return {
    hasTranslation,
    translate,
    destroy,
  };
};
