export enum BrowserGlobalProperties {
  CSRFToken = "CSRF-Token",
}

export enum BrowserCustomEvents {
  DOMDefaultResponse = "v.dom.default.response",
  DOMTemplateResponse = "v.dom.template.response",
  HTTPDone = "v.http.done",
  HTTPError = "v.http.error",
  HTTPLoading = "v.http.loading",
  HTTPRedirect = "v.http.redirect",
}
