const filesTreeElement: HTMLFormElement = document.getElementById("files-tree");
const headerCheckboxElement = filesTreeElement?.querySelector(
  ".file-checkox-header"
);

const numberOfSelected = () => {
  return filesTreeElement.querySelectorAll("input.file-item-checkox:checked")
    .length;
};

const fileCheckboxes = (): Element[] => {
  return Array.from(
    filesTreeElement.querySelectorAll("input.file-item-checkox")
  );
};
const numberOfFiles = filesTreeElement ? fileCheckboxes().length : 0;

const updateCheckboxesOnchange = () => {
  const selected = numberOfSelected();
  const submitButton: HTMLFormElement = filesTreeElement.querySelector(
    "button[type=submit]"
  );

  if (selected > 0) {
    if (selected == numberOfFiles) setHeaderCheckbox("checked");
    else setHeaderCheckbox("indeterminate");
    submitButton.removeAttribute("disabled");
  } else {
    setHeaderCheckbox("unchecked");
    submitButton.setAttribute("disabled", "");
  }
};

const setHeaderCheckbox = (status: string) => {
  const checked = status === "checked";
  const indeterminate = status === "indeterminate";

  headerCheckboxElement.checked = checked;
  headerCheckboxElement.indeterminate = indeterminate;
};

const setAllFilesCheckbox = (checked: boolean) => {
  const checkBoxes = fileCheckboxes();
  checkBoxes.forEach((element: Element) => {
    element.checked = checked;
  });
};

const headerRowCheckboxInit = () => {
  headerCheckboxElement.addEventListener("change", () => {
    setAllFilesCheckbox(numberOfSelected() != numberOfFiles);
    updateCheckboxesOnchange();
  });
};

const fileRowCheckboxInit = () => {
  const checkBoxes = fileCheckboxes();

  checkBoxes.forEach((element: Element) => {
    element.addEventListener("change", () => {
      updateCheckboxesOnchange();
    });
  });
};

const postDownloadInit = () => {
  filesTreeElement.addEventListener("submit", (event: Event) => {
    const form: HTMLFormElement = event.target;
    form.submit();
    event.preventDefault();
    setAllFilesCheckbox(false);
    updateCheckboxesOnchange();
  });
};

const directoryDownloadInit = () => {
  const dirDownloads: Element[] = Array.from(
    filesTreeElement.querySelectorAll(".directory-download")
  );

  dirDownloads.forEach((element: Element) => {
    element.addEventListener("click", (event) => {
      event.preventDefault();
      const hiddenInput = element.querySelector("input");
      hiddenInput.removeAttribute("disabled");
      filesTreeElement.submit();
      hiddenInput.setAttribute("disabled", "");
    });
  });
};

document.addEventListener("DOMContentLoaded", () => {
  if (filesTreeElement && filesTreeElement.classList.contains("multiselect")) {
    headerRowCheckboxInit();
    fileRowCheckboxInit();
    postDownloadInit();
  }

  if (filesTreeElement) directoryDownloadInit();
});
