import { ApplicationController } from "../../shared/application-controller";
import { I18nHelper } from "@veridapt/core";
import {
  BrowserCustomEvents,
  CustomEventsHelper,
  DOMHelper,
  HTTPHelper,
} from "@veridapt/browser-helpers";

const isCypressEnvironment = Reflect.has(window, "Cypress");
const isCaptureSupported =
  Reflect.has(document.createElement("input"), "capture") ||
  isCypressEnvironment;

export default class extends ApplicationController {
  static targets = ["input"];
  static values = {
    disabled: Boolean,
    loading: Boolean,
    parameterName: String,
    reloadUrl: String,
    uploadPath: String,
  };

  declare readonly inputTarget?: HTMLInputElement;

  declare readonly parameterNameValue?: string;
  declare readonly reloadUrlValue?: string;
  declare readonly uploadPathValue?: string;

  declare disabledValue: boolean;
  declare loadingValue: boolean;

  private customEvents?: CustomEventsHelper;
  private dom?: DOMHelper;
  private http?: HTTPHelper;
  private i18n?: I18nHelper;
  private loadingIndicatorFragment?: DocumentFragment;

  connect() {
    if (isCaptureSupported) {
      this.initialiseHelpers();
      this.initialiseCustomEvents();
      void this.initializeLoadingIndicator();
      this.toggleHiddenState(false);
    }
  }

  disconnect() {
    if (isCaptureSupported) {
      this.toggleHiddenState(true);
    }
  }

  disabledValueChanged() {
    this.updateDisabledState();
  }

  loadingValueChanged() {
    this.updateLoadingIndicatorState();
    this.updateDisabledState();
  }

  private uploadFile(event: Event) {
    const files = this.inputTarget?.files;
    if (files && !this.disabledValue) {
      const [file] = Array.from(files);
      if (file) {
        void this.submitFormData(file);
      }
    }
    event.preventDefault();
  }

  private initialiseHelpers() {
    this.customEvents = this.useCustomEvents(this.element);
    this.dom = this.useDOM({ context: this.element });
    this.http = this.useHTTP({ context: this.element });
    this.i18n = this.useI18n();
  }

  private initialiseCustomEvents() {
    this.customEvents?.onEach(
      [
        BrowserCustomEvents.HTTPDone,
        () => {
          this.loadingValue = false;
        },
      ],
      [
        BrowserCustomEvents.HTTPLoading,
        () => {
          this.loadingValue = true;
        },
      ]
    );
  }

  private async initializeLoadingIndicator() {
    this.loadingIndicatorFragment = await this.dom?.buildFragment(
      "<v-loading-indicator type='small'></v-loading-indicator>"
    );
  }

  private addLoadingIndicator(): void {
    if (this.loadingIndicatorFragment) {
      this.dom?.prependFragment(this.element, this.loadingIndicatorFragment);
    }
  }

  private hasLoadingIndicator(): boolean {
    return this.element.firstChild?.nodeName === "V-LOADING-INDICATOR";
  }

  private removeLoadingIndicator(): void {
    this.element.firstChild?.remove();
  }

  private async submitFormData(file: File) {
    if (this.parameterNameValue && this.uploadPathValue) {
      const formData = new FormData();
      const fileName = this.buildImageCaptureFileName(file);
      formData.append(this.parameterNameValue, file, fileName);
      await this.http?.patch(this.uploadPathValue, formData);
      this.reloadPage();
    }
  }

  private buildImageCaptureFileName(file: File): string {
    const fileNameParts = file.name.split(".");
    const fileNamePrefix =
      this.i18n?.translate("file_name.image_capture") ?? "";
    const fileNameTimestamp = new Date().toISOString().slice(0, -1);
    if (fileNameParts.length > 1 && !isCypressEnvironment) {
      fileNameParts.splice(
        0,
        fileNameParts.length - 1,
        fileNamePrefix,
        fileNameTimestamp
      );
    }
    return fileNameParts.join(".");
  }

  private reloadPage() {
    if (this.reloadUrlValue && window.location.href !== this.reloadUrlValue) {
      window.location.assign(this.reloadUrlValue);
    } else {
      window.location.reload();
    }
  }

  private toggleHiddenState(isHidden: boolean) {
    this.element.toggleAttribute("hidden", isHidden);
  }

  private updateDisabledState() {
    const isDisabled = this.disabledValue || this.loadingValue;
    this.element.classList.toggle("disabled", isDisabled);
    this.inputTarget?.toggleAttribute("disabled", isDisabled);
  }

  private updateLoadingIndicatorState() {
    const isLoading = this.loadingValue;
    const wasLoading = this.hasLoadingIndicator();
    if (isLoading && !wasLoading) {
      this.addLoadingIndicator();
    } else if (wasLoading && !isLoading) {
      this.removeLoadingIndicator();
    }
  }
}
