import { ApplicationController } from "../shared/application-controller";
import { DOMHelper, EventsHelper, HTTPHelper } from "@veridapt/browser-helpers";

export default class extends ApplicationController {
  static values = {
    src: String,
  };

  declare srcValue: string;

  private defaultContent: DocumentFragment | undefined;
  private dom: DOMHelper | undefined;
  private events: EventsHelper<WindowEventMap> | undefined;
  private http: HTTPHelper | undefined;

  connect() {
    this.dom = this.useDOM({ context: this.element });
    this.events = this.useEvents<WindowEventMap>(window);
    this.http = this.useHTTP({ context: this.element });
    this.cacheDefaultContent();
    this.initialiseEventHandlers();
  }

  disconnect() {
    this.showDefaultContent();
  }

  srcValueChanged() {
    requestAnimationFrame(() => {
      void this.handleUpdate();
    });
  }

  changePage(event: MouseEvent) {
    const pageURL = new URL(window.location);
    const pageSearch = new URLSearchParams(pageURL.search);

    const anchor = event.currentTarget as HTMLAnchorElement | null;
    const anchorUrl = new URL(anchor.href);
    const anchorParams = new URLSearchParams(anchorUrl.search);

    const anchorPage = anchorParams.get("page");
    if (anchorPage) {
      pageSearch.set("page", anchorPage);
    } else {
      pageSearch.delete("page");
    }

    pageURL.search = pageSearch;
    window.history.pushState({}, "", pageURL.toString());
    void this.handleUpdate();
    event.preventDefault();
    event.stopPropagation();
  }

  clearFilter(event: MouseEvent) {
    if (event.ctrlKey || event.metaKey) {
      return;
    } else {
      event.preventDefault();
      event.stopPropagation();
    }

    const currentParams = new URLSearchParams(
      window.location.hash.replace("#", "")
    );
    const newParams = new URLSearchParams();
    for (const [hashParamKey, hashParamValue] of currentParams.entries()) {
      if (!hashParamKey.startsWith("filter")) {
        newParams.append(hashParamKey, hashParamValue);
      }
    }

    window.location.hash = newParams.toString();
  }

  linkFilter(event: MouseEvent) {
    if (event.ctrlKey || event.metaKey) {
      return;
    } else {
      event.preventDefault();
      event.stopPropagation();
    }

    const filter = new URLSearchParams(window.location.hash.replace("#", ""));
    const {
      filterType,
      filterValue,
    }: { filterType: string; filterValue: string | boolean } =
      event.params as object;
    const filterParamName = `filter[${filterType}][]`;

    if (filterParamName && filterValue) {
      filter.set(filterParamName, filterValue);
    } else {
      filter.delete(filterParamName);
    }
    window.location.hash = filter.toString();
  }

  selectFilter(event: MouseEvent) {
    const select = event.currentTarget as HTMLSelectElement | null;
    const filter = new URLSearchParams(window.location.hash.replace("#", ""));
    const { filterType }: { filterType: string } = event.params as object;
    const filterParamName = `filter[${filterType}][]`;
    if (select.value) {
      filter.set(filterParamName, select.value);
    } else {
      filter.delete(filterParamName);
    }
    window.location.hash = filter.toString();
  }

  inputFilter(event: KeyboardEvent) {
    if (event.key == "Enter") {
      const input = event.currentTarget as HTMLInputElement | null;
      const filter = new URLSearchParams(window.location.hash.replace("#", ""));
      const { filterType }: { filterType: string } = event.params as object;
      const filterParamName = `filter[${filterType}][]`;
      if (input.value.trim()) {
        filter.append(filterParamName, input.value.trim());
      }
      window.location.hash = filter.toString();
    }
  }

  removeFilter(event: MouseEvent) {
    const filter = new URLSearchParams(window.location.hash.replace("#", ""));
    const { filterType, filterValue }: { filterType: string } =
      event.params as object;
    const filterParamName = `filter[${filterType}][]`;
    const newFilter = new URLSearchParams();
    for (const [key, value] of filter.entries()) {
      if (key !== filterParamName || value != filterValue) {
        newFilter.append(key, value);
      }
    }

    window.location.hash = newFilter.toString();
  }

  sort(event: MouseEvent) {
    const pageURL = new URL(window.location);
    const pageSearch = new URLSearchParams(pageURL.search);

    const anchor = event.currentTarget as HTMLAnchorElement | null;
    const anchorUrl = new URL(anchor.href);
    const anchorParams = new URLSearchParams(anchorUrl.search);

    const anchorSortColumn = anchorParams.get("sort_column");
    if (anchorSortColumn) {
      pageSearch.set("sort_column", anchorSortColumn);
    } else {
      pageSearch.delete("sort_column");
    }
    const anchorSortDirection = anchorParams.get("sort_direction");
    if (anchorSortDirection) {
      pageSearch.set("sort_direction", anchorSortDirection);
    } else {
      pageSearch.delete("sort_direction");
    }

    pageURL.search = pageSearch;
    window.history.pushState({}, "", pageURL.toString());
    void this.handleUpdate();
    event.preventDefault();
    event.stopPropagation();
  }

  private buildContentRequestInfo(): {
    url: string;
    data: FormData | undefined;
  } {
    const queryParams = new URLSearchParams(window.location.search);
    const hashParams = new URLSearchParams(
      window.location.hash.replace("#", "")
    );
    let filterData: FormData | undefined;

    for (const [hashParamKey, hashParamValue] of hashParams.entries()) {
      if (hashParamKey.startsWith("filter")) {
        filterData ??= new FormData();
        filterData.append(hashParamKey, hashParamValue);
      } else {
        queryParams.set(hashParamKey, hashParamValue);
      }
    }

    return {
      url: this.srcValue + "?" + queryParams.toString(),
      data: filterData,
    };
  }

  private cacheDefaultContent() {
    this.defaultContent = new DocumentFragment();
    Array.from(this.element.children).forEach((childElement) => {
      const node = childElement.cloneNode(true);
      this.defaultContent?.append(node);
    });
  }

  private async handleResponseContent(content: string): Promise<void> {
    const fragment = await this.dom?.handleResponseContent(content);
    if (fragment) {
      this.updateContent(fragment);
    }
  }

  private async handleUpdate(): Promise<void> {
    this.showDefaultContent();
    const content = await this.requestContent();
    if (typeof content == "string") {
      await this.handleResponseContent(content);
    }
    requestAnimationFrame(() => {
      const focusOn: HTMLElement | null =
        this.element.querySelector("[autofocus]");
      if (focusOn && typeof focusOn.focus == "function") {
        focusOn.focus();
      }
    });
  }

  private initialiseEventHandlers() {
    this.events?.on("contextmenu", (event: MouseEvent) => {
      this.handleCancellableFilterEvent(event);
    });

    this.events?.on("hashchange", () => {
      void this.handleUpdate();
    });
  }

  private handleCancellableFilterEvent(event: MouseEvent) {
    if (this.isFilterEvent(event)) {
      this.cancelEvent(event);
    }
  }

  private isFilterEvent(event: Event): boolean {
    const anchor = this.getEventTargetAnchor(event);
    return Boolean(anchor?.dataset?.action?.startsWith("filter-frame"));
  }

  private getEventTargetAnchor(event: Event): HTMLAnchorElement | null {
    const target = event.target as HTMLElement | null;
    return target?.closest("a") ?? null;
  }

  private cancelEvent(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  // NB: Note that content is retrieved via POST request to avoid
  //     GET request query string length issues.
  private async requestContent(): Promise<string | null | undefined> {
    const { url, data } = this.buildContentRequestInfo();
    return await this.http?.post(url, data);
  }

  private showDefaultContent() {
    if (this.defaultContent) {
      this.updateContent(this.defaultContent);
    }
  }

  private updateContent(fragment: DocumentFragment) {
    this.dom?.replaceElementContent(this.element, fragment);
  }
}
