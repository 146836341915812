import { ApplicationController } from "../shared/application-controller";
import { DOMHelper, HTTPHelper } from "@veridapt/browser-helpers";

enum InlineAction {
  Append,
  Prepend,
  Replace,
  Content,
  Remove,
}

interface EventParams {
  action: keyof typeof InlineAction;
  src: string | undefined;
  notifySuccess: boolean;
  onSuccessEvent: string | undefined;
}

export default class extends ApplicationController {
  static targets = ["container"];
  declare readonly containerTarget: Element | undefined;

  static values = {
    highlightClass: { type: String, default: "highlight" },
    isHighlighted: Boolean,
  };

  highlightClassValue: string;
  dom: DOMHelper | undefined;
  http: HTTPHelper | undefined;

  connect(): void {
    this.dom = this.useDOM({ context: this.element });
    this.http = this.useHTTP({ context: this.element });
  }

  isHighlightedValueChanged() {
    if (this.isHighlightedValue) {
      this.element.classList.add(this.highlightClassValue);
      setTimeout(() => {
        this.isHighlightedValue = false;
      }, 3000);
    } else {
      this.element.classList.remove(this.highlightClassValue);
    }
  }

  async load(event: Event) {
    if (event instanceof MouseEvent && (event.ctrlKey || event.metaKey)) {
      return;
    } else {
      event.preventDefault();
      event.stopPropagation();
    }

    const { src } = event.params as EventParams;
    const anchor = event.currentTarget as HTMLAnchorElement | null;
    const url = new URL(src ?? anchor.href);
    const content = await this.http?.get(url);

    this.updateContent(event, content);
  }

  async destroy(event: MouseEvent) {
    if (event.ctrlKey || event.metaKey) {
      return;
    } else {
      event.preventDefault();
      event.stopPropagation();
    }

    const { src } = event.params as EventParams;
    const anchor = event.currentTarget as HTMLAnchorElement | null;
    const url = new URL(src ?? anchor.href);
    const content = await this.http?.delete(url);

    this.updateContent(event, content);
  }

  remove(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    this.element.remove();
  }

  async submitForm(event: SubmitEvent) {
    event.preventDefault();
    event.stopPropagation();

    const form = event.target as HTMLFormElement | null;
    const formData = new FormData(form);
    const formUrl = new URL(form.action);
    const content = await this.http?.post(formUrl, formData);

    this.updateContent(event, content);
  }

  private async updateContent(event: Event, content: string): void {
    const { action, notifySuccess, onSuccessEvent } =
      event.params as EventParams;

    const fragment = await this.dom?.handleResponseContent(content);

    if (notifySuccess) {
      switch (InlineAction[action]) {
        case InlineAction.Append:
        case InlineAction.Prepend:
        case InlineAction.Content:
          this.isHighlightedValue = true;
          break;
        case InlineAction.Replace:
          if (fragment) {
            (fragment.firstChild as HTMLElement).setAttribute(
              "data-inline-is-highlighted-value",
              true
            );
          }
          break;
      }
    }
    const container = this.hasContainerTarget
      ? this.containerTarget
      : this.element;
    switch (InlineAction[action]) {
      case InlineAction.Append:
        this.dom?.appendFragment(container, fragment);
        break;
      case InlineAction.Prepend:
        this.dom?.prependFragment(container, fragment);
        break;
      case InlineAction.Replace:
        this.dom?.replaceElement(container, fragment);
        break;
      case InlineAction.Content:
        this.dom?.replaceElementContent(container, fragment);
        break;
      case InlineAction.Remove:
        container.remove();
        break;
    }

    if (onSuccessEvent) {
      window.dispatchEvent(new CustomEvent(onSuccessEvent));
    }
  }
}
