import { ApplicationController } from "../shared/application-controller";
import {
  BrowserCustomEvents,
  CustomEventsHelper,
  DOMHelper,
} from "@veridapt/browser-helpers";

export default class extends ApplicationController {
  static targets = ["content"];
  static values = { url: String };

  declare readonly contentTarget: HTMLElement;
  declare readonly urlValue: string;

  customEvents: CustomEventsHelper | undefined;
  dom: DOMHelper | undefined;

  connect(): void {
    this.customEvents = this.useCustomEvents(this.element);
    this.dom = this.useDOM();

    this.toggleModalDialogWidthOverride(true);
  }

  disconnect(): void {
    this.toggleContentAspectRatioOverride(false);
    this.toggleModalDialogWidthOverride(false);
    this.toggleModalDialogScrollOverride(false);
  }

  urlValueChanged(): void {
    this.isPDFPreview() ? this.loadPDF() : this.loadImage();
  }

  private isPDFPreview() {
    return this.urlValue?.endsWith(".pdf");
  }

  private loadImage() {
    this.toggleContentAspectRatioOverride(false);
    this.toggleModalDialogScrollOverride(true);

    const image = new Image();
    image.className = "d-block img-fluid mx-auto";
    image.src = this.urlValue;
    image.setAttribute("data-testid", "image-document-preview");

    this.bindHTTPErrorHandler(image);

    image.addEventListener("load", () => {
      this.replaceContent(image);
    });
  }

  private loadPDF() {
    this.toggleContentAspectRatioOverride(true);
    this.toggleModalDialogScrollOverride(false);

    const iframe = document.createElement("iframe");
    iframe.src = `${this.urlValue}?#view=fitH`;
    iframe.setAttribute("data-testid", "pdf-document-preview");

    this.bindHTTPErrorHandler(iframe);
    this.replaceContent(iframe);
  }

  private bindHTTPErrorHandler(element: HTMLElement) {
    element.addEventListener("error", () => {
      this.customEvents?.trigger(BrowserCustomEvents.HTTPError);
    });
  }

  private replaceContent(element: HTMLElement) {
    window.requestAnimationFrame(() => {
      this.dom?.emptyElementContents(this.contentTarget);
      this.contentTarget.appendChild(element);
    });
  }

  private toggleContentAspectRatioOverride(enableOverride: boolean) {
    ["ratio", "ratio-21x9"].forEach((className) => {
      this.contentTarget.classList.toggle(className, enableOverride);
    });
  }

  private getModalDialog() {
    return this.element.querySelector(".modal-dialog");
  }

  private toggleModalDialogScrollOverride(enableOverride: boolean) {
    this.getModalDialog()?.classList?.toggle(
      "modal-dialog-scrollable",
      enableOverride
    );
  }

  private toggleModalDialogWidthOverride(enableOverride: boolean) {
    this.getModalDialog()?.classList?.toggle("mw-75", enableOverride);
  }
}
