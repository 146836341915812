import { ApplicationController } from "../shared/application-controller";
import { I18nHelper } from "@veridapt/core";
import {
  BrowserCustomEvents,
  CustomEventListener,
  CustomEventsHelper,
  DOMHelper,
  DOMTemplateResponseEventDetail,
  HTTPResponseEventDetail,
} from "@veridapt/browser-helpers";

export default class extends ApplicationController {
  static targets = ["alertTemplate", "templateOutput"];
  declare readonly alertTemplateTarget: HTMLTemplateElement;
  declare readonly templateOutputTargets: HTMLElement[];

  private customEvents: CustomEventsHelper | undefined;
  private dom: DOMHelper | undefined;
  private i18n: I18nHelper | undefined;

  connect(): void {
    this.customEvents = this.useCustomEvents(window);
    this.dom = this.useDOM({ context: this.element });
    this.i18n = this.useI18n();

    this.initializeCustomEvents();
  }

  private initializeCustomEvents() {
    this.customEvents?.onEach(
      [BrowserCustomEvents.HTTPError, this.httpErrorEventListener],
      [BrowserCustomEvents.HTTPRedirect, this.httpRedirectEventListener],
      [
        BrowserCustomEvents.DOMTemplateResponse,
        this.domTemplateResponseEventListener,
      ]
    );
  }

  private domTemplateResponseEventListener: CustomEventListener = (
    event: CustomEvent<DOMTemplateResponseEventDetail>
  ): void => {
    if (event.detail && !event.defaultPrevented) {
      const { id, content } = event.detail;
      this.handleDOMTemplateContent(id, content);
    }
  };

  private httpErrorEventListener: CustomEventListener = (
    event: CustomEvent<HTTPResponseEventDetail>
  ): void => {
    if (!event.defaultPrevented) {
      const i18nOptions = { scope: "errors.messages.http" };
      const status = String(event.detail?.status);
      const message = this.i18n?.hasTranslation(status, i18nOptions)
        ? this.i18n?.translate(status, i18nOptions)
        : this.i18n?.translate("generic", i18nOptions);
      if (message) {
        this.handleAlertContent(message);
      }
    }
  };

  private httpRedirectEventListener: CustomEventListener = (
    event: CustomEvent
  ): void => {
    if (!event.defaultPrevented) {
      location.reload();
    }
  };

  // NB: Manually build alert text elements using low-level DOM API methods
  //     to avoid scenario where external HTML sanitisation library can't be
  //     lazy-loaded due to HTTP connection errors.
  private handleAlertContent(alertMessage: string) {
    const alertContent = document.importNode(
      this.alertTemplateTarget.content,
      true
    );
    const alertText = document.createElement("span");
    alertText.innerText = alertMessage;
    alertContent.firstElementChild?.appendChild(alertText);
    this.handleDOMTemplateContent("alert", alertContent);
  }

  private handleDOMTemplateContent(
    id: string,
    content: DocumentFragment
  ): void {
    const target = this.templateOutputTargets.find(
      (templateOutputTarget) => templateOutputTarget.dataset.templateId === id
    );

    if (target) {
      this.dom?.replaceElementContent(target, content);
    } else {
      console.error(`Unknown template output target '${id}'`);
    }
  }
}
