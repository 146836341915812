import { useDOM, useHTTP } from "@veridapt/browser-helpers";

const dealLoanFacility = document.getElementById("deal_loan_facility");
const http = useHTTP();
const dom = useDOM();

if (dealLoanFacility) {
  const dataUrl = dealLoanFacility.dataset.url as string;
  const dealTypeSelector = document.getElementById(
    "deal_deal_template_id"
  ) as HTMLSelectElement;

  dealTypeSelector?.addEventListener("change", function () {
    void (async () => {
      if (dealTypeSelector.value) {
        const content = await http.get(dataUrl, {
          deal_template_id: dealTypeSelector.value,
        });
        if (content) {
          const fragment = await dom.handleResponseContent(content);
          dom.replaceElementContent(dealLoanFacility, fragment);
          return;
        }
      }
      dom.emptyElementContents(dealLoanFacility);
    })();
  });
}
