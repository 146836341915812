import "trix";
import "@rails/actiontext";
import { start as startActiveStorage } from "@rails/activestorage";
import { start as startUJS } from "@rails/ujs";

startActiveStorage();
startUJS();

// Prevent Trix editor accepting file attachments
document.addEventListener("trix-file-accept", (event) => {
  event.preventDefault();
});
