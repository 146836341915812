const channels = require.context("../../channels", true, /_channel\.ts$/);
const components = require.context(
  "../../../components",
  true,
  /_component\.ts$/
);

channels.keys().forEach(channels);
components.keys().forEach(components);

require.context("../../images", true);
