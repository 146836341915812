import { ApplicationController } from "../shared/application-controller";

export default class extends ApplicationController {
  static targets = ["predicateField", "subjectField", "subjectFieldset"];
  static values = {
    disabled: false,
    displayDisabledFields: false,
    displayParentOfDisabledFields: false,
    fieldValueDependencies: Object,
  };

  declare readonly predicateFieldTarget: HTMLInputElement | HTMLSelectElement;
  declare readonly subjectFieldTargets: HTMLInputElement[];
  declare readonly subjectFieldsetTarget?: HTMLElement;

  declare readonly disabledValue: boolean;
  declare readonly displayDisabledFieldsValue: boolean;
  declare readonly displayParentOfDisabledFieldsValue: boolean;
  declare readonly fieldValueDependenciesValue: Record<string, string[]>;

  connect() {
    requestAnimationFrame(() => {
      this.updateEnabledFields();
    });
  }

  updateEnabledFields() {
    const enabledSubjectFieldsState = this.getEnabledSubjectFieldsState();
    enabledSubjectFieldsState.forEach((isEnabled, field) => {
      this.toggleEnabledFields(field, isEnabled);
    });

    if (this.subjectFieldsetTarget && !this.displayDisabledFieldsValue) {
      const isEnabledSubjectFields = Array.from(
        enabledSubjectFieldsState.values()
      ).some(Boolean);

      this.toggleElementDisplay(
        this.subjectFieldsetTarget,
        isEnabledSubjectFields
      );
    }
  }

  private getEnabledSubjectFieldsState(): Map<HTMLInputElement, boolean> {
    const enabledSubjectFieldValues = this.getEnabledSubjectFieldValues();
    const enabledSubjectFieldsState = new Map<HTMLInputElement, boolean>();

    this.subjectFieldTargets.forEach((subjectField) => {
      const subjectFieldValue = subjectField.value.trim();
      const isEnabled = enabledSubjectFieldValues.includes(subjectFieldValue);
      enabledSubjectFieldsState.set(subjectField, isEnabled);
    });

    return enabledSubjectFieldsState;
  }

  private getEnabledSubjectFieldValues(): string[] {
    const predicateFieldValue = this.predicateFieldTarget.value.trim();
    const enabledSubjectFieldValues =
      this.fieldValueDependenciesValue[predicateFieldValue];
    return enabledSubjectFieldValues?.map(String) ?? [];
  }

  private toggleElementDisplay(
    element: HTMLElement | null,
    isDisplayed: boolean
  ) {
    element?.classList?.toggle("d-none", !isDisplayed);
  }

  private toggleEnabledFields(field: HTMLInputElement, isEnabled: boolean) {
    field.disabled = this.disabledValue || !isEnabled;

    if (!this.displayDisabledFieldsValue) {
      this.toggleElementDisplay(field, isEnabled);

      if (!this.displayParentOfDisabledFieldsValue) {
        this.toggleElementDisplay(field.parentElement, isEnabled);
      }
    }
  }
}
