import { Helper } from "@veridapt/core";

export type CustomEventListener = (event: CustomEvent) => void;

export interface CustomEventsHelper extends Helper {
  clear: () => void;
  on: (type: string, listener: CustomEventListener) => void;
  onEach: (...listeners: [string, CustomEventListener][]) => void;
  trigger: (type: string, detail?: Record<string, unknown>) => boolean;
}

export function useCustomEvents(target: EventTarget): CustomEventsHelper {
  const customEventListeners = new Map<string, Set<CustomEventListener>>();

  const addListener = (type: string, listener: CustomEventListener) => {
    target.addEventListener(type, listener as EventListener);
    registerListener(type, listener);
  };

  const addListeners = (...listeners: [string, CustomEventListener][]) => {
    listeners.forEach(([type, listener]) => addListener(type, listener));
  };

  const clearListeners = () => {
    for (const [type, listeners] of customEventListeners) {
      for (const listener of listeners) {
        target.removeEventListener(type, listener as EventListener);
      }
    }
    customEventListeners.clear();
  };

  const dispatchEvent = <T>(type: string, detail?: T) =>
    target.dispatchEvent(
      new CustomEvent<T>(type, {
        bubbles: true,
        cancelable: true,
        detail,
      })
    );

  const registerListener = (type: string, listener: CustomEventListener) => {
    const eventTypeListeners =
      customEventListeners.get(type) ?? new Set<CustomEventListener>();
    if (!customEventListeners.has(type)) {
      customEventListeners.set(type, eventTypeListeners);
    }
    eventTypeListeners.add(listener);
  };

  return {
    clear: clearListeners,
    destroy: clearListeners,
    on: addListener,
    onEach: addListeners,
    trigger: dispatchEvent,
  };
}
