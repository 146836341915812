import { ApplicationController } from "../shared/application-controller";
import { Dropdown } from "bootstrap";
import {
  BrowserCustomEvents,
  CustomEventListener,
  CustomEventsHelper,
  DOMHelper,
} from "@veridapt/browser-helpers";

export default class extends ApplicationController {
  static targets = ["toggle"];
  declare readonly toggleTarget: HTMLButtonElement | undefined;

  static values = { disabled: Boolean, loading: Boolean };
  declare disabledValue: boolean;
  declare loadingValue: boolean;

  private customEvents: CustomEventsHelper | undefined;
  private dom: DOMHelper | undefined;
  private dropdown: Dropdown | undefined;
  private loadingIndicatorFragment: DocumentFragment | undefined;

  connect(): void {
    this.customEvents = this.useCustomEvents(this.element);
    this.dom = this.useDOM({ context: this.element });

    this.initialiseCustomEvents();
    this.initializeDropdown();
    void (async () => {
      await this.initializeLoadingIndicator();
    })();

    this.updateToggleTargetState();
  }

  disconnect(): void {
    this.dropdown?.dispose();
  }

  disabledValueChanged(): void {
    this.updateToggleTargetState();
  }

  loadingValueChanged(): void {
    this.updateLoadingIndicatorState();
    this.updateToggleTargetState();
  }

  private initialiseCustomEvents() {
    this.customEvents?.onEach(
      [BrowserCustomEvents.HTTPLoading, this.httpLoadingEventListener],
      [BrowserCustomEvents.HTTPDone, this.httpDoneEventListener]
    );
  }

  private initializeDropdown() {
    if (this.toggleTarget) {
      this.dropdown = Dropdown.getOrCreateInstance(this.toggleTarget);
    }
  }

  private httpLoadingEventListener: CustomEventListener = (): void => {
    this.dropdown?.hide();
    this.loadingValue = true;
  };

  private httpDoneEventListener: CustomEventListener = (): void => {
    this.loadingValue = false;
  };

  private updateToggleTargetState() {
    if (this.toggleTarget) {
      this.toggleTarget.disabled = this.disabledValue || this.loadingValue;
    }
  }

  private updateLoadingIndicatorState() {
    const wasLoading = this.hasLoadingIndicator();
    if (this.loadingValue && !wasLoading) {
      this.addLoadingIndicator();
    } else if (wasLoading && !this.loadingValue) {
      this.removeLoadingIndicator();
    }
  }

  private async initializeLoadingIndicator() {
    this.loadingIndicatorFragment = await this.dom?.buildFragment(
      "<v-loading-indicator type='small'></v-loading-indicator>"
    );
  }

  private addLoadingIndicator(): void {
    if (this.loadingIndicatorFragment && this.toggleTarget) {
      this.dom?.prependFragment(
        this.toggleTarget,
        this.loadingIndicatorFragment
      );
    }
  }

  private hasLoadingIndicator(): boolean {
    return this.toggleTarget?.firstChild?.nodeName === "V-LOADING-INDICATOR";
  }

  private removeLoadingIndicator(): void {
    this.toggleTarget?.firstChild?.remove();
  }
}
