import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

const application = Application.start();

const context = require.context(
  "../../controllers",
  true,
  /.*controller\.[tj]s$/
);

application.load(definitionsFromContext(context));
