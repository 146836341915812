import { ApplicationController } from "../shared/application-controller";

import {
  CustomEventListener,
  CustomEventsHelper,
} from "@veridapt/browser-helpers";

import {
  WorkflowControlStateEventType,
  WorkflowControlStateEventDetail,
} from "./workflow-control-controller";

export default class extends ApplicationController {
  static values = {
    id: String,
    enabled: Boolean,
  };

  declare readonly idValue?: string;
  declare enabledValue?: boolean;

  private customEvents?: CustomEventsHelper;

  enabledValueChanged() {
    ["disabled", "hidden"].forEach((attributeName) => {
      this.element.toggleAttribute(attributeName, !this.enabledValue);
    });
  }

  connect() {
    this.customEvents = this.useCustomEvents(globalThis);

    this.initialiseCustomEvents();
  }

  private WorkflowControlStateEventListener: CustomEventListener = (
    event: CustomEvent<WorkflowControlStateEventDetail>
  ) => {
    const { id, attachmentEnabled, closed } = event.detail ?? {};
    if (id === this.idValue) {
      this.enabledValue = attachmentEnabled && !closed;
    }
  };

  private initialiseCustomEvents() {
    this.customEvents?.onEach([
      WorkflowControlStateEventType,
      this.WorkflowControlStateEventListener,
    ]);
  }
}
