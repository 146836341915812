import { ApplicationController } from "../shared/application-controller";
import { Modal } from "bootstrap";

export default class extends ApplicationController {
  static values = { reloadWindowOnClose: Boolean };
  declare reloadWindowOnCloseValue: boolean;

  modal: Modal | undefined;

  connect(): void {
    this.modal = Modal.getOrCreateInstance(this.element);

    // If no ID provided to support manually showing modal via BS data attributes,
    // assume that modal is intended to be automatically shown.
    if (!this.element.id) {
      this.modal.show();
    }
  }

  disconnect(): void {
    this.modal?.dispose();
  }

  checkWindowReload(): void {
    if (this.reloadWindowOnCloseValue) {
      window.location.reload();
    }
  }
}
