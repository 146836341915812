import { ApplicationController } from "../shared/application-controller";
import { formatDistance, parseJSON } from "date-fns";

export default class extends ApplicationController {
  static targets = [
    "dateInput",
    "durationOutput",
    "durationOutputWrapper",
    "outputsContainer",
    "timeZoneOutput",
    "timeZoneOutputWrapper",
  ];

  declare readonly dateInputTargets: HTMLInputElement[];
  declare readonly durationOutputTarget: HTMLElement;
  declare readonly durationOutputWrapperTarget: HTMLElement;
  declare readonly hasTimeZoneOutputWrapperTarget: boolean;
  declare readonly outputsContainerTarget: HTMLElement;
  declare readonly timeZoneOutputTarget: HTMLElement;
  declare readonly timeZoneOutputWrapperTarget: HTMLElement;

  private duration?: string;

  connect() {
    this.updateOutputs();
  }

  updateOutputs() {
    this.duration = this.calculateDuration();
    this.updateDurationOutput();
    this.updateOutputsContainer();
  }

  private calculateDuration(): string | undefined {
    const inputTimes = this.dateInputTargets
      .map(({ value }) => value)
      .filter(Boolean)
      .map((value) => parseJSON(value).getTime());

    if (inputTimes.length > 1) {
      const fromDate = new Date(Math.min(...inputTimes));
      const toDate = new Date(Math.max(...inputTimes));

      const duration = formatDistance(toDate, fromDate);
      return duration.charAt(0).toUpperCase() + duration.slice(1);
    }
  }

  private updateDurationOutput() {
    const isHidden = !this.duration;
    this.durationOutputTarget.textContent = this.duration ?? "";
    this.durationOutputTarget.toggleAttribute("hidden", isHidden);
    this.durationOutputWrapperTarget.toggleAttribute("hidden", isHidden);
  }

  private updateOutputsContainer() {
    const isHidden = !(this.duration || this.hasTimeZoneOutputWrapperTarget);
    this.outputsContainerTarget.toggleAttribute("hidden", isHidden);
  }
}
