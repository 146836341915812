import { ApplicationController } from "../shared/application-controller";
import Tribute from "tributejs";
import Trix from "trix";

export default class extends ApplicationController {
  static targets = ["field"];

  connect() {
    this.editor = this.fieldTarget.editor;
    this.element.focus();
    this.initializeTributes();
    this.http = this.useHTTP();
  }

  disconnect() {
    this.user_tribute.detach(this.fieldTarget);
    this.team_tribute.detach(this.fieldTarget);
  }

  initializeTributes() {
    const fetchUsers = this.fetchUsers.bind(this);
    const fetchTeams = this.fetchTeams.bind(this);

    this.user_tribute = new Tribute({
      allowSpaces: true,
      requireLeadingSpace: false,
      lookup: "name",
      values: fetchUsers,
    });
    this.user_tribute.attach(this.fieldTarget);
    this.user_tribute.range.pasteHtml = this._pasteHtml.bind(this);

    this.team_tribute = new Tribute({
      trigger: "#",
      allowSpaces: true,
      requireLeadingSpace: false,
      lookup: "name",
      values: fetchTeams,
    });
    this.team_tribute.attach(this.fieldTarget);
    this.team_tribute.range.pasteHtml = this._pasteHtml.bind(this);

    this.fieldTarget.addEventListener("tribute-replaced", this.replaced);
  }

  async fetchUsers(text, callback) {
    const users = await this.http.getJSON("/mentions/users.json", {
      query: text,
    });
    callback(users ?? []);
  }

  async fetchTeams(text, callback) {
    const teams = await this.http.getJSON("/mentions/teams.json", {
      query: text,
    });
    callback(teams ?? []);
  }

  replaced(e) {
    let mention = e.detail.item.original;
    let attachment = new Trix.Attachment({
      sgid: mention.sgid,
      content: mention.content,
    });
    this.editor.insertAttachment(attachment);
    this.editor.insertString(" ");
  }

  _pasteHtml(html, startPos, endPos) {
    let position = this.editor.getPosition();
    let tributeLength = endPos - startPos;
    let trixStartPos = position - tributeLength;
    let trixEndPos = position;
    this.editor.setSelectedRange([trixStartPos, trixEndPos]);
    this.editor.deleteInDirection("backward");
  }
}
