import { ApplicationController } from "../../shared/application-controller";
import { Tooltip } from "bootstrap";

export default class extends ApplicationController {
  static targets = ["content"];
  static values = {
    html: Boolean,
    placement: { type: String, default: "top" },
    showTruncatedContent: Boolean,
    title: String,
  };

  declare readonly htmlValue: boolean;
  declare readonly placementValue: string;
  declare readonly showTruncatedContentValue: boolean;
  declare readonly titleValue: string;

  declare readonly contentTarget?: HTMLElement;
  declare readonly hasContentTarget: boolean;

  private tooltip?: Tooltip;

  connect() {
    this.initialiseTooltip();
  }

  disconnect() {
    this.destroyTooltip();
  }

  enable() {
    this.tooltip?.enable();
  }

  hideAndDisable() {
    this.tooltip?.hide();
    this.tooltip?.disable();
  }

  private initialiseTooltip() {
    this.tooltip ??= new Tooltip(this.element, {
      container: "body",
      customClass: "v-mw-30",
      html: this.htmlValue || this.showTruncatedContentValue,
      placement: this.placementValue as Tooltip.PopoverPlacement,
      title: () =>
        this.showTruncatedContentValue && this.hasTruncatedContent()
          ? this.buildTitleUsingTruncatedContent()
          : this.getTitle(),
    });
  }

  private destroyTooltip() {
    this.tooltip &&= void this.tooltip.dispose();
  }

  private buildTitleUsingTruncatedContent(): string {
    const content = this.getContent();
    const title = this.getTitle();
    return content && title ? `${content}<hr>${title}` : `${content}${title}`;
  }

  private getContent(): string {
    return this.getContentElement().textContent?.trim() ?? "";
  }

  private getContentElement(): HTMLElement {
    return (
      this.hasContentTarget ? this.contentTarget : this.element
    ) as HTMLElement;
  }

  private getTitle(): string {
    const title =
      this.titleValue?.trim() || this.element.getAttribute("title")?.trim();
    return title ?? "";
  }

  private hasTruncatedContent(): boolean {
    const contentElement = this.getContentElement();
    if (!contentElement.parentElement) {
      return false;
    }

    const tmpElement = contentElement.cloneNode(true) as HTMLElement;
    try {
      tmpElement.classList.add(
        "invisible",
        "overflow-visible",
        "position-fixed"
      );
      contentElement.parentElement.appendChild(tmpElement);

      const { width: defaultWidth } = this.element.getBoundingClientRect();
      const { width: overflowWidth } = tmpElement.getBoundingClientRect();
      return defaultWidth < overflowWidth;
    } finally {
      tmpElement.remove();
    }
  }
}
