import { ApplicationController } from "../shared/application-controller";
import { HTTPHelper } from "@veridapt/browser-helpers";

interface Status {
  id: number;
  klass: string;
  state: string;
  state_icon_class: string;
  state_text_class: string;
  children_open_string: string;
  disabled: boolean;
}

interface Param {
  url: string;
}

export default class extends ApplicationController {
  static targets = [
    "button",
    "stateText",
    "stateChildren",
    "stateIconClass",
    "stateIconType",
  ];

  http: HTTPHelper | undefined;
  declare buttonTargets: Array<Element>;
  declare stateTextTargets: Array<Element>;
  declare stateChildrenTargets: Array<Element>;
  declare stateIconClassTargets: Array<Element>;

  connect(): void {
    this.http = this.useHTTP({ context: this.element });
  }

  load = ({ params }: { params: Param }) => {
    void (async () => {
      const result = await this.requestContent(params.url);
      if (result && Array.isArray(result)) {
        return this.handleResponseContent(result as Status[]);
      }
    })();
  };

  private async requestContent(
    url: string
  ): Promise<Record<string, unknown> | null | undefined> {
    if (this.http) {
      return await this.http.patchJSON(url);
    }
  }

  private handleResponseContent(content: Status[]): void {
    /* iterate over each 'control' target in the DOM */
    this.stateTextTargets.forEach((element) => {
      const key = element.getAttribute("data-state-id");

      /* find in the returned Status[] array (via the JSON response) the related response */
      const stateObj = content.find((obj: Status) => {
        return key === `state-${obj.klass}-${obj.id}`;
      });

      /* update the state text */
      if (stateObj) {
        const stateTextElement = element as HTMLParagraphElement;
        stateTextElement.textContent = stateObj.state;
      }
    });

    this.stateChildrenTargets.forEach((element) => {
      const key = element.getAttribute("data-state-id");

      /* find in the returned Status[] array (via the JSON response) the related response */
      const stateObj = content.find((obj: Status) => {
        return key === `state-${obj.klass}-${obj.id}`;
      });

      /* update the children state text */
      if (stateObj) {
        const stateTextElement = element as HTMLParagraphElement;
        stateTextElement.textContent = stateObj.children_open_string;
      }
    });

    this.stateIconClassTargets.forEach((element) => {
      const removeClasses: string[] = [];
      const key = element.getAttribute("data-state-icon-class-id");

      const stateObj = content.find((obj: Status) => {
        return key === `state-icon-class-${obj.klass}-${obj.id}`;
      });

      if (stateObj) {
        const stateIconClassElement = element as HTMLSpanElement;

        /* remove the old classes in a two step process, the classList iterable doesn't like being
         * mutated within an iterable loop */
        stateIconClassElement.classList.forEach((cls) => {
          if (/(v-workflow-step-text|bi-)/.exec(cls)) {
            removeClasses.push(cls);
          }
        });

        removeClasses.forEach((cls) => {
          stateIconClassElement.classList.remove(cls);
        });

        /* add the current state classes back in */
        stateIconClassElement.classList.add(stateObj.state_text_class);

        stateObj.state_icon_class.split(/ /).forEach((cls) => {
          stateIconClassElement.classList.add(cls);
        });
      }
    });

    this.buttonTargets.forEach((element) => {
      const key = element.getAttribute("data-button-id");
      const statusObj = content.find((obj: Status) => {
        return key === `button-${obj.klass}-${obj.id}`;
      });

      if (statusObj) {
        const button = element as HTMLInputElement;
        button.disabled = statusObj.disabled;
      }
    });
  }
}
