import { ApplicationController } from "../shared/application-controller";
import { Collapse } from "bootstrap";

export default class extends ApplicationController {
  static targets = ["button", "content"];
  static values = {
    id: String,
    expanded: Boolean,
    horizontal: Boolean,
    textCollapsed: String,
    textExpanded: String,
  };

  declare readonly buttonTarget: HTMLButtonElement;
  declare readonly contentTarget: HTMLElement;
  declare idValue: string;
  declare expandedValue: boolean;
  declare horizontalValue: boolean;
  declare textCollapsedValue: string;
  declare textExpandedValue: string;

  buttonTargetConnected(button: HTMLButtonElement) {
    this.updateButtonTargetText();

    Object.entries({
      "aria-controls": this.idValue,
      "aria-expanded": String(this.expandedValue),
      "data-bs-target": `#${this.idValue}`,
      "data-bs-toggle": "collapse",
    }).forEach(setAttributeFor(button));
  }

  buttonTargetDisconnected(button: HTMLButtonElement) {
    [
      "aria-controls",
      "aria-expanded",
      "data-bs-target",
      "data-bs-toggle",
    ].forEach(removeAttributeFor(button));
  }

  contentTargetConnected(content: HTMLElement) {
    Object.entries({
      collapse: true,
      "collapse-horizontal": this.horizontalValue,
      show: this.expandedValue,
    }).forEach(setClassFor(content));
  }

  contentTargetDisconnected(content: HTMLElement) {
    ["collapse", "collapse-horizontal", "show"].forEach(
      removeClassFor(content)
    );
  }

  expandedValueChanged(value: boolean) {
    if (value !== this.isExpanded()) {
      const collapse = Collapse.getOrCreateInstance(this.contentTarget);
      collapse[value ? "show" : "hide"]();
    }
  }

  horizontalValueChanged(value: boolean) {
    this.contentTarget.classList.toggle("collapse-horizontal", value);
  }

  updateExpandedValue(event: Event) {
    requestAnimationFrame(() => {
      if (this.expandedValue !== this.isExpanded()) {
        this.expandedValue = !this.expandedValue;
      }
      this.updateButtonTargetText();
    });
    // Ensure that events don't expand/collapse ancestor `collapse` instances
    event.stopPropagation();
  }

  private isExpanded() {
    return this.buttonTarget.getAttribute("aria-expanded") === "true";
  }

  private updateButtonTargetText() {
    if (this.textCollapsedValue && this.textExpandedValue) {
      this.buttonTarget.textContent = this.expandedValue
        ? this.textExpandedValue
        : this.textCollapsedValue;
    }
  }
}

const setAttributeFor =
  (button: HTMLButtonElement) =>
  ([attributeName, attributeValue]: [string, string]) =>
    button.setAttribute(attributeName, attributeValue);

const setClassFor =
  (content: HTMLElement) =>
  ([className, isEnabled]: [string, boolean]) =>
    content.classList.toggle(className, isEnabled);

const removeAttributeFor =
  (button: HTMLButtonElement) => (attributeName: string) =>
    button.removeAttribute(attributeName);

const removeClassFor = (element: HTMLElement) => (className: string) =>
  element.classList.remove(className);
