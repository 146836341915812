import { ApplicationController } from "../../shared/application-controller";

export default class extends ApplicationController {
  static targets = ["row"];

  declare rowTargets: HTMLElement[] | undefined;

  rowTargetConnected(element: HTMLElement): void {
    this.sortRow(element);
  }

  sortRow(element: HTMLElement): void {
    const oldIndex = this.rowTargets.indexOf(element);
    let newIndex = this.rowTargets.sort(sortingAlgorithm).indexOf(element);

    if (oldIndex == newIndex) {
      return;
    }

    if (newIndex == this.rowTargets.length) {
      this.element.append(element);
      return;
    }

    if (oldIndex < newIndex) {
      newIndex++;
    }

    this.element.insertBefore(element, this.rowTargets[newIndex]);
  }
}

// Check controller tests for the resulting sorting order.
export function sortingAlgorithm(
  elementA: HTMLElement,
  elementB: HTMLElement
): integer {
  const sequenceA = elementA.getAttribute("data-sequence") as string;
  const sequenceB = elementB.getAttribute("data-sequence") as string;
  const idA = elementA.getAttribute("data-id") as string;
  const idB = elementB.getAttribute("data-id") as string;

  if (idA == null) {
    return 1;
  }

  if (idB == null) {
    return -1;
  }

  if (sequenceA == sequenceB) {
    return idA - idB;
  }

  if (sequenceA == null) {
    return 1;
  }

  if (sequenceB == null) {
    return -1;
  }

  return sequenceA - sequenceB;
}
