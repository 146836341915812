import { ApplicationController } from "../shared/application-controller";

interface EventParams {
  text: string | undefined;
}

export default class extends ApplicationController {
  static values = {
    defaultText: String,
  };

  declare defaultTextValue: string;

  askConfirmation(event: Event): void {
    const { text } = event.params as EventParams;

    if (!window.confirm(text || this.defaultTextValue)) {
      event.stopImmediatePropagation();
      event.preventDefault();
    }
  }
}
