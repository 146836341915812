import { dropZoneInit } from "../../javascript/shared/file-drop-zone-init";

function initFileAttachModal(modal: Element) {
  const form = modal.querySelector("form");
  const submit = modal.querySelector(".documents-submit");
  const filesInput = modal.querySelector(
    ".documents-input"
  ) as HTMLInputElement;
  if (!form || !submit || !filesInput) return;

  // Get file size from data attribute
  const maxFileSize = parseInt(form.dataset["maxFileSize"] || "10");

  const dropzone = dropZoneInit(form, {
    previewsContainer: modal.querySelector(".attach-files-preview"),
    maxFilesize: maxFileSize,
  });

  submit.addEventListener("click", () => {
    dropzone.processQueue();
  });

  filesInput.addEventListener("change", () => {
    if (filesInput.files) {
      Array.from(filesInput.files).forEach((f: File) => dropzone.addFile(f));
    }

    // // The following didn't work consistently
    // const noFiles = dropzone.getAcceptedFiles().length == 0;
    // submit.toggleAttribute("disabled", noFiles);

    filesInput.value = "";
  });

  modal.addEventListener("hidden.bs.modal", () => {
    dropzone.removeAllFiles(true);
  });
}

(function () {
  const modals: Element[] = Array.from(
    document.querySelectorAll(".v-modal-file-attach")
  );

  modals.forEach((modal: Element) => {
    initFileAttachModal(modal);
  });
})();
