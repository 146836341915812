import { ApplicationController } from "../shared/application-controller";
import { I18nHelper } from "@veridapt/core";
import {
  BrowserCustomEvents,
  CustomEventListener,
  CustomEventsHelper,
  DOMHelper,
  HTTPHelper,
  HTTPRequestMethod,
} from "@veridapt/browser-helpers";

export const WorkflowControlStateEventType = "v.workflow.control.state";
const WorkflowControlRefreshEventType = "v.workflow.control.refresh";

export type WorkflowControlStateEventDetail =
  | {
      id: string;
      attachmentEnabled: boolean;
      closed: boolean;
    }
  | undefined;

type WorkflowControlRefreshEventDetail =
  | {
      targetId: string;
    }
  | undefined;

export default class extends ApplicationController {
  static values = {
    id: String,
    attachmentEnabled: Boolean,
    authorized: Boolean,
    closed: Boolean,
    loading: Boolean,
    modelKey: String,
    parentId: String,
    parentClosed: Boolean,
    parentModel: String,
    prerequisiteIds: { type: Array<string>, default: [] },
    prerequisitesMet: Boolean,
    refreshUrl: String,
    updated: Boolean,
  };

  declare readonly idValue?: string;
  declare readonly attachmentEnabledValue?: string;
  declare readonly authorizedValue?: boolean;
  declare readonly closedValue?: boolean;
  declare readonly modelKeyValue?: string;
  declare readonly parentIdValue?: string;
  declare readonly parentModelValue?: string;
  declare readonly prerequisiteIdsValue?: string[];
  declare readonly prerequisitesMetValue?: boolean;
  declare readonly refreshUrlValue?: string;

  declare loadingValue?: boolean;
  declare parentClosedValue?: boolean;
  declare updatedValue?: boolean;

  private customEvents?: CustomEventsHelper;
  private dom?: DOMHelper;
  private http?: HTTPHelper;
  private i18n?: I18nHelper;

  connect() {
    this.customEvents = this.useCustomEvents(globalThis);
    this.dom = this.useDOM({ context: this.element });
    this.http = this.useHTTP({ context: this.element });
    this.i18n = this.useI18n();

    this.initialiseCustomEvents();
    this.updateParentClosedErrorMessageTooltip();
  }

  closedValueChanged() {
    if (this.modelKeyValue === "deals") {
      this.removeNewProcessLink();
    }
  }

  loadingValueChanged() {
    this.updateDropdownDisabledValue();
  }

  parentClosedValueChanged() {
    this.updateDropdownDisabledValue();
    this.updateParentClosedErrorMessageTooltip();
  }

  prerequisitesMetValueChanged() {
    this.updateDropdownDisabledValue();
  }

  updatedValueChanged(current: boolean, previous: boolean) {
    if (this.updatedValue) {
      this.updatedValue = false;
      this.triggerDeferredRefreshEvent(this.parentIdValue);
    }
    if (current != previous) {
      this.triggerDeferredStateEvent();
    }
  }

  private initialiseCustomEvents() {
    this.customEvents?.onEach(
      [BrowserCustomEvents.HTTPLoading, this.httpLoadingEventListener],
      [BrowserCustomEvents.HTTPDone, this.httpDoneEventListener],
      [WorkflowControlStateEventType, this.WorkflowControlStateEventListener],
      [
        WorkflowControlRefreshEventType,
        this.WorkflowControlRefreshEventListener,
      ]
    );
  }

  private httpLoadingEventListener: CustomEventListener = (): void => {
    this.loadingValue = true;
  };

  private httpDoneEventListener: CustomEventListener = (): void => {
    this.loadingValue = false;
  };

  private WorkflowControlStateEventListener: CustomEventListener = (
    event: CustomEvent<WorkflowControlStateEventDetail>
  ) => {
    this.handleStateEvent(event.detail?.id, event.detail?.closed);
  };

  private WorkflowControlRefreshEventListener: CustomEventListener = (
    event: CustomEvent<WorkflowControlRefreshEventDetail>
  ) => {
    void this.handleRefreshEvent(event.detail?.targetId);
  };

  private async handleRefreshEvent(targetId?: string) {
    if (targetId === this.idValue) {
      const content = await this.requestContent(this.refreshUrlValue);
      if (typeof content == "string") {
        return this.handleResponseContent(content);
      }
    }
  }

  private async requestContent(
    urlValue?: string
  ): Promise<string | null | undefined> {
    if (this.http && urlValue) {
      return await this.http[HTTPRequestMethod.Get](urlValue);
    }
  }

  private async handleResponseContent(content: string): Promise<void> {
    if (this.dom) {
      const fragment = await this.dom.handleResponseContent(content);
      if (fragment) {
        this.dom.replaceElement(this.element, fragment);
      }
    }
  }

  private handleStateEvent(sourceID?: string, sourceClosed?: boolean) {
    if (sourceID === this.parentIdValue) {
      this.parentClosedValue = sourceClosed;
    }
    if (
      sourceID &&
      sourceClosed != this.prerequisitesMetValue &&
      this.prerequisiteIdsValue?.includes(sourceID)
    ) {
      this.triggerDeferredRefreshEvent(this.idValue);
    }
  }

  private triggerDeferredRefreshEvent(targetId?: string) {
    setTimeout(() => {
      this.customEvents?.trigger(WorkflowControlRefreshEventType, { targetId });
    });
  }

  private triggerDeferredStateEvent() {
    setTimeout(() => {
      this.customEvents?.trigger(WorkflowControlStateEventType, {
        id: this.idValue,
        attachmentEnabled: this.attachmentEnabledValue,
        closed: this.closedValue,
      });
    });
  }

  private updateDropdownDisabledValue() {
    const disabled = Boolean(
      !this.authorizedValue ||
        this.loadingValue ||
        this.parentClosedValue ||
        !this.prerequisitesMetValue
    );
    this.element.setAttribute("data-dropdown-disabled-value", String(disabled));
  }

  private updateParentClosedErrorMessageTooltip() {
    const hasParentClosedErrorMessage =
      this.authorizedValue && this.parentClosedValue;

    this.element.setAttribute(
      "data-ui--tooltip-title-value",
      hasParentClosedErrorMessage ? this.parentClosedErrorMessage : ""
    );
  }

  private get parentClosedErrorMessage(): string {
    return String(
      this.i18n?.translate(
        `errors.messages.workflows.${
          this.modelKeyValue as string
        }.parent_closed`,
        { parent_model: this.parentModelValue }
      )
    );
  }

  private removeNewProcessLink() {
    const element = document.querySelector("a[data-target='new-process-link']");
    element?.classList.toggle("d-none", this.closedValue);
  }
}
