function addTimeZoneCookie() {
  const expires = new Date();
  expires.setTime(expires.getTime() + 24 * 60 * 60 * 1000);
  document.cookie =
    "time_zone=" +
    Intl.DateTimeFormat().resolvedOptions().timeZone +
    ";expires=" +
    expires.toUTCString() +
    ";path=/";
}

addTimeZoneCookie();
