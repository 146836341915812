import { ApplicationController } from "../shared/application-controller";
import {
  DOMHelper,
  HTTPCacheType,
  HTTPHelper,
} from "@veridapt/browser-helpers";
import { Popover } from "bootstrap";

export default class extends ApplicationController {
  static values = { detailsSrc: String };
  declare readonly detailsSrcValue: string | undefined;
  dom: DOMHelper | undefined;
  http: HTTPHelper | undefined;
  popover: Popover | undefined;

  connect(): void {
    this.dom = this.useDOM();
    this.http = this.useHTTP({ cache: HTTPCacheType.Force });
    this.initialiseDetailsPopover();
  }

  disconnect(): void {
    this.destroyDetailsPopover();
  }

  cancel(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
  }

  updateDetailsContent(): void {
    void (async () => {
      if (this.detailsSrcValue) {
        const content = await this.http?.get(this.detailsSrcValue);
        if (content) {
          const detailsElement = this.getDetailsElement();
          const fragment = await this.dom?.handleResponseContent(content);
          if (detailsElement && fragment) {
            this.dom?.replaceElementContent(detailsElement, fragment);
            this.popover?.update();
          }
        }
      }
    })();
  }

  private initialiseDetailsPopover() {
    if (!this.popover) {
      void (async () => {
        const loadingIndicator = await this.dom?.buildFragment(
          "<v-loading-indicator class='d-block text-primary'></v-loading-indicator>"
        );

        if (loadingIndicator) {
          this.popover = new Popover(this.element, {
            content: loadingIndicator.cloneNode(true) as Element,
            container: "body",
            customClass: "v-mw-30",
            html: true,
            placement: "auto",
            trigger: "focus",
          });
        }
      })();
    }
  }

  private destroyDetailsPopover() {
    this.popover?.dispose();
    delete this.popover;
  }

  private getDetailsElement(): Element | null {
    if (this.popover) {
      const tipElement = Reflect.get(this.popover, "tip") as Element;
      return tipElement?.querySelector(".popover-body");
    }

    return null;
  }
}
