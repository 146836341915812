import { BrowserCustomEvents, useCustomEvents } from "..";
import { Helper } from "@veridapt/core";
import { useHTML } from "../html/html-helper";

export type DOMHelperOptions = {
  context?: EventTarget;
};

export type DOMTemplateResponseEventDetail =
  | Pick<HTMLTemplateElement, "content" | "id">
  | undefined;

export interface DOMHelper extends Helper {
  appendFragment: (element: Element, fragment: DocumentFragment) => void;
  buildFragment: (rawHTML: string) => Promise<DocumentFragment>;
  emptyElementContents: (element: Element) => void;
  handleResponseContent: (rawHTML: string) => Promise<DocumentFragment | null>;
  prependFragment: (element: Element, fragment: DocumentFragment) => void;
  replaceElement: (element: Element, fragment: DocumentFragment) => void;
  replaceElementContent: (element: Element, fragment: DocumentFragment) => void;
}

export function useDOM(helperOptions?: DOMHelperOptions): DOMHelper {
  const { context = globalThis } = helperOptions ?? {};
  const customEvents = useCustomEvents(context);
  const html = useHTML();

  const destroy = () => {
    customEvents.destroy();
    html.destroy();
  };

  const appendFragment = (element: Element, fragment: DocumentFragment) =>
    element.append(importNode(fragment));

  const buildFragment = async (rawHTML: string): Promise<DocumentFragment> => {
    const safeHTML = await html.sanitize(rawHTML);
    return safeHTML
      ? document.createRange().createContextualFragment(safeHTML)
      : new DocumentFragment();
  };

  const emptyElementContents = (element: Element) => {
    while (element.hasChildNodes()) {
      element.firstChild?.remove();
    }
  };

  const handleResponseContent = async (rawHTML: string) => {
    const fragment = await buildFragment(rawHTML);
    const isTemplateResponse = fragment.firstChild?.nodeName === "TEMPLATE";

    if (isTemplateResponse) {
      triggerDOMTemplateResponseEvent(
        fragment.firstChild as HTMLTemplateElement
      );
      if (fragment.childNodes.length == 1) {
        return null;
      }
      fragment.removeChild(fragment.firstChild);
    } else {
      triggerDOMDefaultResponseEvent();
    }

    return fragment;
  };

  const importNode = (fragment: DocumentFragment): Node =>
    document.importNode(fragment, true);

  const prependFragment = (element: Element, fragment: DocumentFragment) =>
    element.prepend(importNode(fragment));

  const replaceElement = (element: Element, fragment: DocumentFragment) =>
    element.replaceWith(importNode(fragment));

  const replaceElementContent = (
    element: Element,
    fragment: DocumentFragment
  ) => {
    emptyElementContents(element);
    appendFragment(element, fragment);
  };

  const triggerDOMDefaultResponseEvent = () => {
    customEvents.trigger(BrowserCustomEvents.DOMDefaultResponse);
  };

  const triggerDOMTemplateResponseEvent = (
    templateElement: HTMLTemplateElement
  ) => {
    customEvents.trigger(
      BrowserCustomEvents.DOMTemplateResponse,
      templateElement as DOMTemplateResponseEventDetail
    );
  };

  return {
    appendFragment,
    buildFragment,
    destroy,
    emptyElementContents,
    handleResponseContent,
    prependFragment,
    replaceElement,
    replaceElementContent,
  };
}
