import { ApplicationController } from "../shared/application-controller";

export default class extends ApplicationController {
  static targets = ["scroll"];

  scrollTargetConnected(scrollTarget: HTMLElement) {
    const isNestedScrollTarget = this.isNestedScrollTarget(scrollTarget);
    const { scrollTop: containerInitialScrollTop } = this.element;

    // When nested inside a second scrollable, that will scroll too
    this.scrollToTarget(scrollTarget);

    // When we want only the nested scrollable scrolled, reset the containers scroll
    if (isNestedScrollTarget) {
      this.element.scrollTop = containerInitialScrollTop;
    }
  }

  private isNestedScrollTarget(scrollTarget: HTMLElement): boolean {
    return scrollTarget.dataset.autoScrollType === "nested";
  }

  private isSmoothScrollBehaviour(scrollTarget: HTMLElement): boolean {
    return scrollTarget.dataset.autoScrollBehaviour === "smooth";
  }

  private scrollToTarget(scrollTarget: HTMLElement) {
    const isSmoothScrollBehaviour = this.isSmoothScrollBehaviour(scrollTarget);
    const behavior = isSmoothScrollBehaviour ? "smooth" : "auto";
    scrollTarget.scrollIntoView({ behavior });
  }
}
