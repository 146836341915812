import flatpickr from "flatpickr";
import { ApplicationController } from "../../shared/application-controller";

/* eslint-disable import/no-duplicates */
import { enAU } from "date-fns/locale";
import { format, zonedTimeToUtc } from "date-fns-tz";
import { formatISO, parse, parseJSON } from "date-fns";
/* eslint-enable import/no-duplicates */

type DatePicker = flatpickr.Instance;
const datePickerLocale = enAU;
const datePickerFormat = "P HH:mm";

export default class extends ApplicationController {
  static values = {
    timeZone: String,
  };

  declare timeZoneValue: string;
  private dateDisplayInput?: HTMLInputElement;
  private datePicker?: DatePicker;

  get input(): HTMLInputElement {
    return this.element as HTMLInputElement;
  }

  get value(): Date | undefined {
    const { value } = this.input;
    if (value) {
      return parseJSON(value);
    }
  }

  set value(date: Date | undefined) {
    if (date) {
      this.input.setAttribute("value", formatISO(date));
    } else {
      this.input.removeAttribute("value");
    }
  }

  connect() {
    this.initialiseDateDisplayInput();
    this.initialiseDatePicker();
    this.toggleElementVisibility(false);
  }

  disconnect() {
    this.destroyDatePicker();
    this.destroyDateDisplayInput();
    this.toggleElementVisibility(true);
  }

  private initialiseDateDisplayInput() {
    const input = document.createElement("input");
    input.className = "form-control";
    input.toggleAttribute("readonly", true);

    this.element.insertAdjacentElement("afterend", input);
    this.dateDisplayInput = input;
  }

  private initialiseDatePicker() {
    if (this.dateDisplayInput) {
      this.datePicker = flatpickr(this.dateDisplayInput, {
        appendTo: document.body,
        defaultDate: this.value,
        enableTime: true,
        time_24hr: true,
        formatDate: (date) => this.formatUTCDate(date),
        parseDate: (date) => this.parseUTCDate(date),
        onChange: ([timeZoneDate]) => {
          this.value = timeZoneDate
            ? this.convertToUTCDate(timeZoneDate)
            : undefined;
        },
      });
    }
  }

  private destroyDateDisplayInput() {
    this.dateDisplayInput?.remove();
    delete this.dateDisplayInput;
  }

  private destroyDatePicker() {
    this.datePicker?.destroy();
    delete this.datePicker;
  }

  private convertToUTCDate(timeZoneDate: Date): Date {
    return zonedTimeToUtc(timeZoneDate, this.timeZoneValue);
  }

  private formatUTCDate(utcDate: Date): string {
    return format(utcDate, datePickerFormat, {
      locale: datePickerLocale,
      timeZone: this.timeZoneValue,
    });
  }

  private parseUTCDate(utcDate: string): Date {
    return parse(utcDate, datePickerFormat, new Date(), {
      locale: datePickerLocale,
    });
  }

  private toggleElementVisibility(isVisible: boolean) {
    this.element.toggleAttribute("hidden", !isVisible);
  }
}
