import { ApplicationController } from "../shared/application-controller";

export default class extends ApplicationController {
  static targets = ["form"];

  declare readonly formTarget: HTMLFormElement;

  connect() {
    this.formTarget.submit();
  }
}
