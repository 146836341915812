// NB: This functionality is a proof-of-concept to demonstrate the capacities provided by
//     modern browsers to support custom HTML elements
//
//    The objective of this proof-of-concept is to demonstrate how custom HTML elements can be easily reused
//    across both back-end rendered HTML (i.e. HAML templates) and front-end rendered HTML (i.e. via JS)
//    without a dependency on a specific client-side or server-side framework.
//
//    Since this proof-of-concept focuses on 'usage' (i.e. rather than 'implemenation') of custom HTML elements,
//    this code has been kept as simple as possible.
//
//     Note that:
//     * This implmentation is *NOT* final, and should *NOT* be used as a template for new features.
//       (There are cleaner ways to implemnt this functionality, but this serves as a quick MVP)
//
//     * This code is written as a concete example that can be referenced in a pending implementation proposal.
//       (Personally, I'd prefer to write the proposal first, but there's demand to see running code up-front.)
//
//     * This functionality is intended to be used for low-level UI components that shareable across multiple VERIDAPT applications,
//        and does *NOT* replace Stimulus or ViewComponents in the DFA tech stack.

customElements.define(
  "v-loading-indicator",
  class extends HTMLElement {
    connectedCallback() {
      this.innerHTML = `<div class='${this.spinnerClass}' role='status'><div class='visually-hidden'>Loading...</div></div>`;
    }

    get spinnerClass() {
      const { typeClass } = this;
      return `spinner-border${typeClass ? ` ${typeClass}` : ""}`;
    }

    get type() {
      return this.getAttribute("type");
    }

    set type(value) {
      if (value) {
        this.setAttribute("type", value);
      } else {
        this.removeAttribute("type");
      }
      const spinner = this.firstChild as HTMLElement;
      if (spinner) {
        spinner.className = this.spinnerClass;
      }
    }

    get typeClass() {
      switch (this.type) {
        case "auto":
          return "h-100 w-100";
        case "small":
          return "spinner-border-sm";
        default:
          return "";
      }
    }
  }
);
