// Used to initialize Dropzone object
// How to show an error returned by the server?
// - send back a proper HTTP status code in the range of 4xx
// - If the Content-Type of your response is text/plain, you can just return the text without any further markup.
// - If the Content-Type is application/json, Dropzone will use the error property of the provided object. Eg.: { "error": "File could not be saved." }
//
// TODO: dropzone messages I18n
import Dropzone from "dropzone";

export function dropZoneInit(elem: Element, opts = {}): Dropzone {
  const defaultOpts = {
    paramName: "documents",
    autoProcessQueue: false,
    maxFiles: 10,
    parallelUploads: 10,
    addRemoveLinks: true,
  };
  const dropzone = new Dropzone(elem, Object.assign(defaultOpts, opts));

  // queuecomplete is not ideal because it's fired when user pick one file which is too big
  dropzone.on("success", () => {
    if (
      dropzone.getUploadingFiles().length === 0 &&
      dropzone.getQueuedFiles().length === 0
    ) {
      // TODO not refresh page, instead:
      // hide the modal and make ajax calls to update the number of docs
      setTimeout(() => {
        location.reload();
      }, 1000);
    }
  });

  return dropzone;
}
