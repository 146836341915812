import { Helper } from "@veridapt/core";

export interface EventsHelper<T> extends Helper {
  on: <K extends keyof T>(type: K, listener: (event: T[K]) => void) => void;
  clear: () => void;
}

export function useEvents<T extends GlobalEventHandlersEventMap>(
  target: EventTarget
): EventsHelper<T> {
  type EventMap = T;
  type EventType = keyof EventMap;
  type EventListener<K extends EventType> = (event: EventMap[K]) => void;

  const listeners = new Map<EventType, Set<EventListener<EventType>>>();

  const addListener = <K extends EventType>(
    type: K,
    listener: (event: T[K]) => void
  ) => {
    const add = Reflect.get(target, "addEventListener") as (
      type: K,
      listener: EventListener<K>
    ) => void;

    add.call(target, type, listener);
    registerListener(type, listener);
  };

  const clearListeners = () => {
    const remove = Reflect.get(target, "removeEventListener") as (
      type: EventType,
      listener: EventListener<EventType>
    ) => void;

    for (const [type, typeListeners] of listeners) {
      for (const typeListener of typeListeners) {
        remove.call(target, type, typeListener);
      }
    }
    listeners.clear();
  };

  const registerListener = <K extends EventType>(
    type: EventType,
    listener: EventListener<K>
  ) => {
    const typeListeners =
      listeners.get(type) ?? new Set<EventListener<EventType>>();
    if (!listeners.has(type)) {
      listeners.set(type, typeListeners);
    }
    typeListeners.add(listener as EventListener<EventType>);
  };

  return {
    clear: clearListeners,
    destroy: clearListeners,
    on: addListener,
  };
}
