import Dropzone from "dropzone";
import { ApplicationController } from "../shared/application-controller";
import { Modal } from "bootstrap";
import { dropZoneInit } from "../shared/file-drop-zone-init";

import {
  CustomEventListener,
  CustomEventsHelper,
} from "@veridapt/browser-helpers";

import {
  WorkflowControlStateEventType,
  WorkflowControlStateEventDetail,
} from "./workflow-control-controller";

let dropZoneTimer: NodeJS.Timeout;
const removeFileDragStyles = () => {
  if (dropZoneTimer) clearTimeout(dropZoneTimer);

  dropZoneTimer = setTimeout(() => {
    document.body.classList.remove("document-file-drag-active");
  }, 70); // > 50 ms which is interval of dragover event
};

const dropPageInit = () => {
  window.addEventListener(
    "drop",
    (event: DragEvent) => {
      // when dragged files are dropped not in any dropzone, this will:
      // - stop browser open the dropped file in a new tab
      // - remove all highlights
      event.preventDefault();
      removeFileDragStyles();
    },
    false
  );

  window.addEventListener(
    "dragover",
    (event: DragEvent) => {
      event.preventDefault(); // without this, drop event doesn't fire
      const types: string[] = event?.dataTransfer?.types as string[];
      // without this check, dragging any element will trigger the drop target UI
      if (types.includes("Files")) {
        if (dropZoneTimer) clearTimeout(dropZoneTimer);
        document.body.classList.add("document-file-drag-active");
      }
    },
    false
  );

  window.addEventListener("dragleave", removeFileDragStyles, false);
};

class FilePreviewModal extends Modal {
  declare title: string;
  declare currentDropzone: Dropzone;

  constructor(elm: Element) {
    super(elm);

    elm.addEventListener("hidden.bs.modal", () => {
      if (this.currentDropzone) this.currentDropzone.removeAllFiles();
    });

    elm.addEventListener("show.bs.modal", () => {
      const titleElm = elm.querySelector(".attach-target-title");
      if (titleElm) titleElm.textContent = this.title;
    });

    elm.querySelector("#drops-submit")?.addEventListener("click", (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (this.currentDropzone) this.currentDropzone.processQueue();
    });
  }

  setTargetTitle(title: string) {
    this.title = title;
  }

  setCurrentZone(dropzone: Dropzone) {
    this.currentDropzone = dropzone;
  }
}

const filePreviewModal: FilePreviewModal | undefined = (() => {
  dropPageInit();

  const modalElm = document.getElementById("drop-preview-modal");
  if (modalElm) {
    return new FilePreviewModal(modalElm);
  }
})();

export default class extends ApplicationController {
  static values = { id: String, enabled: Boolean, title: String };
  static targets = ["form"];

  declare readonly idValue?: string;
  declare readonly titleValue?: string;
  declare enabledValue?: boolean;

  declare readonly formTarget?: HTMLFormElement;

  private customEvents?: CustomEventsHelper;
  private dropzone?: Dropzone;

  connect() {
    this.customEvents = this.useCustomEvents(globalThis);

    this.initialiseCustomEvents();
  }

  enabledValueChanged() {
    if (this.enabledValue) {
      this.createDropzone();
    } else {
      this.destroyDropzone();
    }
  }

  private WorkflowControlStateEventListener: CustomEventListener = (
    event: CustomEvent<WorkflowControlStateEventDetail>
  ) => {
    const { id, attachmentEnabled, closed } = event.detail ?? {};
    if (id === this.idValue) {
      this.enabledValue = attachmentEnabled && !closed;
    }
  };

  private initialiseCustomEvents() {
    this.customEvents?.onEach([
      WorkflowControlStateEventType,
      this.WorkflowControlStateEventListener,
    ]);
  }

  private createDropzone() {
    if (this.formTarget && !this.dropzone) {
      const previewsContainer = document.getElementById("files-preview");
      this.dropzone = dropZoneInit(this.formTarget, { previewsContainer });
      this.element.classList.toggle("drop-target", true);
    }
  }

  private destroyDropzone() {
    this.element.classList.toggle("drop-target", false);
    this.dropzone?.destroy();
    delete this.dropzone;
  }

  private handleFileDrop(event: DragEvent) {
    if (
      filePreviewModal &&
      this.dropzone &&
      this.enabledValue &&
      this.isValidFileDropEvent(event)
    ) {
      filePreviewModal.setCurrentZone(this.dropzone);
      filePreviewModal.setTargetTitle(this.titleValue ?? "");
      filePreviewModal.show();
    }
  }

  private isValidFileDropEvent(event: DragEvent) {
    return event?.dataTransfer?.types?.includes("Files");
  }
}
