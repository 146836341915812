import { ApplicationController } from "../shared/application-controller";

export default class extends ApplicationController {
  static targets = ["templateSelect", "recurrenceField", "processType"];
  static values = {
    templatesRecurrence: Object,
  };

  declare readonly templateSelectTarget: HTMLInputElement;
  declare readonly recurrenceFieldTargets: HTMLInputElement[];
  declare readonly processTypeTargets: HTMLInputElement[];

  declare readonly templatesRecurrenceValue: Record<string, boolean>;

  onTemplateSelected() {
    const templateId = this.templateSelectTarget.value.trim();
    const noRecurrence = !this.templatesRecurrenceValue[templateId];

    if (noRecurrence) {
      // if selected process template doesn't support recurrence,
      // ensure the selected process type is always 'one-off'
      const oneOffProcessInput = this.processTypeTargets.filter(
        (e) => e.value.trim() === "one_off"
      )[0];

      if (!oneOffProcessInput.checked) {
        oneOffProcessInput.click();
      }
    }

    // disable all input fields for process recurrence
    this.recurrenceFieldTargets.forEach((field) => {
      field.disabled = noRecurrence;
    });
  }
}
