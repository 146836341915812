// Usage
//
// <div>
//   <a href="#"
//      data-controller="toggle"
//      data-action="toggle#toggleDisplay"
//      data-toggle-display-class="d-none"
//      data-toggle-element-id-value="element-id">Toggle #element-id</a>
//   <div id="element-id">Element to toggle CSS class</div>
// </div>

import { ApplicationController } from "../shared/application-controller";

export default class extends ApplicationController {
  static classes = ["display"];
  static values = { elementId: String };

  declare readonly displayClass: string;
  declare readonly elementIdValue: string;

  toggleDisplay(event: Event): void {
    event.preventDefault();

    document
      .getElementById(this.elementIdValue)
      .classList.toggle(this.displayClass);
  }
}
